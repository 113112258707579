import React from 'react';
import TemplateMaker from './TemplateMaker';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const Home = () => {

    return (
        <div style={{ padding: 0 }}>
            <Header />
            <TemplateMaker />
            <Footer/>
        </div>
    )
}

export { Home };