import React from 'react';
import styles from "../styles/Header.module.css";

const Footer = () => {
    return (
    <div className={styles.footer}>
        <div className={styles.footerText}>© 2022 CodeTemplateMaker.com  - All Rights Reserved</div>
      </div>
    )
}

export { Footer }