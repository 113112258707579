/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import styles from '../styles/TemplateMaker.module.css';
import { FiCopy } from 'react-icons/fi';

const Templater = (props) => {
  const [displayObjs, setDisplayObjs] = useState([]);
  const [possibleAttributes, setPossibleAttributes] = useState([]);
  const [activeAttributes, setActiveAttributes] = useState([]);
  const [baseVariables, setBaseVariables] = useState([]);
  const [attributeSelectId, setAttributeSelectId] = useState('');
  const [useEffectFired, setUseEffectFired] = useState(false);

  const getGreatestDepthIndex = (array) => {
    let index = 0;
    let greatestDepth = 0;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.depth > greatestDepth) {
        greatestDepth = element.depth;
        index = i;
      }
    }
    return index;
  };

  const mapOneAttrLocationObj = (attribute, locationObj) => {
    if (attribute.variables.length === 0) {
      return locationObj.codeBlock;
    } else {
      //add all sub-variable's locations to an array if within bounds of the attributeLoc and sort
      let vLocationObjs = [];
      attribute.variables.forEach((v) => {
        v.variableLocations.forEach((variableLoc) => {
          if(variableLoc.startIndex >= locationObj.startIndex
            && variableLoc.startIndex <= locationObj.endIndex
            && variableLoc.docIndex === locationObj.docIndex){
              vLocationObjs.push(variableLoc);
            }
        })
      });
      vLocationObjs = vLocationObjs.sort((a, b) => a.startIndex - b.startIndex);
      let attrLocString = "";
      //add end of codeBlock if not included in last vlocationObj
      if (
        vLocationObjs[vLocationObjs.length - 1].endIndex <
        locationObj.endIndex
      ) {
        const endOfString = locationObj.codeBlock.substring(
          //to get the start index of the variable within the location we have to subtract the location's startIndex
          vLocationObjs[vLocationObjs.length - 1].endIndex - locationObj.startIndex + 1
        );
        attrLocString = endOfString;
      }
      //for each locationObj add it
      for (let n = vLocationObjs.length - 1; n >= 0; n--) {
        const vLoc = vLocationObjs[n];
        let val;
        //variableLoc for baseVariable
        if(vLoc.baseVariableIndex !== null){
          val = baseVariables[vLoc.baseVariableIndex].value;
        //variableLoc with parentAttributeIndex  
        } else {
          val = activeAttributes[vLoc.activeAttributeIndex].variables[vLoc.indexWithinParentVariables].value;
        }

        // make upper or lowercase accordingly
        if(vLoc.isUpperCase) {
          val = `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
        } else {
          val = `${val.charAt(0).toLowerCase()}${val.slice(1)}`;
        }
        
        attrLocString = `${val}${attrLocString}`
        // and if there's another locationObj before it add any text in between
        if (n > 0 && vLoc.startIndex > vLocationObjs[n - 1].endIndex) {
          const betweenText = locationObj.codeBlock.substring(
            vLocationObjs[n - 1].endIndex + 1 - locationObj.startIndex,
            vLoc.startIndex - locationObj.startIndex
          );
          attrLocString = `${betweenText}${attrLocString}`;
        }
      }

      //add beginning of file if not included in first locationObj
      if (vLocationObjs[0].startIndex > 0) {
        attrLocString = `${locationObj.codeBlock.substring(
          0,
          vLocationObjs[0].startIndex - locationObj.startIndex
        )}${attrLocString}`;
      }

      return attrLocString;
    }
  };

  const mapThroughAttributesForLocation = (locationObj) => {
    const activeAttributesWithId = activeAttributes.filter(
      (a) => a.originalIndex === locationObj.attributeIndex
    );
    if (activeAttributesWithId.length > 0) {
      let locString = "";
      for (let i = 0; i < activeAttributesWithId.length; i++) {
        const a = activeAttributesWithId[i];
        locString = `${locString}${mapOneAttrLocationObj(a, locationObj)}`;
      }
      return locString;
    } else {
      return "";
    }
  };

  const mapDisplayObj = (obj) => {
    //text
    if (obj.text !== null && obj.text !== undefined) {
      return obj.text;
      //variable with no parent attribute
    } else if (obj.variableName !== null && obj.variableName !== undefined && (obj.parentAttributeIndex === undefined || obj.parentAttributeIndex === null)) {
      //need to set variableIndex after removing other variables from baseVariables
      let val = baseVariables[obj.baseVariableIndex].value;
      if(obj.isUpperCase) {
        val = `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
      } else {
        val = `${val.charAt(0).toLowerCase()}${val.slice(1)}`;
      }
      return val;
      //variable with parent attribute
    } else if (obj.variableName !== null && obj.variableName !== undefined && obj.activeAttributeIndex !== undefined) {
      return activeAttributes[obj.activeAttributeIndex].variables[obj.indexWithinParentVariables].value;
      // attribute
    } else if (obj.attributeName !== null && obj.attributeName !== undefined) {
      return mapThroughAttributesForLocation(obj);
    }
  };

  const mapFinalCode = (displayObjs, index) => {
    let docCode = '';
    for (let i = 0; i < displayObjs.length; i++) {
        docCode = `${docCode}${mapDisplayObj(displayObjs[i])}`;
    }

    return (<div key={`file-${index}`}>
        <div>
          <label>{`File Name ${index + 1} : ${props.main.files[index].fileName}`}</label>
          <FiCopy onClick={()=> copyToClipboard(docCode, props.main.files[index].fileName)}/>
        </div>
        
        <div className={styles.codecontainer}>
            {docCode}
        </div>   
    </div>)
  }
    


  const createDisplayObjs = (locationObjsArr) => {
    let fileObjs = [];
    for (let k = 0; k < props.main.files.length; k++) {
      const file = props.main.files[k];
      //insert empty array for each file
      fileObjs.push([]);
      //an array of location objs for that document
      const locationObjs = locationObjsArr[k];
      //add end of file if not included in last locationObj
      if (
        locationObjs[locationObjs.length - 1].endIndex <
        file.originalCode.length
      ) {
        const endOfFile = file.originalCode.substring(
          locationObjs[locationObjs.length - 1].endIndex + 1
        );
        fileObjs[k].unshift({ text: endOfFile });
      }
      //for each locationObj add it
      for (let i = locationObjs.length - 1; i >= 0; i--) {
        const locationObj = locationObjs[i];
        fileObjs[k].unshift(locationObj);
        // and if there's another locationObj before it add any text in between
        if (i > 0 && locationObj.startIndex > locationObjs[i - 1].endIndex) {
          const betweenText = file.originalCode.substring(
            locationObjs[i - 1].endIndex + 1,
            locationObj.startIndex
          );
          fileObjs[k].unshift({ text: betweenText });
        }
      }

      //add beginning of file if not included in first locationObj
      if (locationObjs[0].startIndex > 0) {
        const start = file.originalCode.substring(0, locationObjs[0].startIndex);
        fileObjs[k].unshift({ text: start });
      }
    }

    setDisplayObjs(fileObjs);
  };

  const createTree = () => {
    let newAttributes = [];
    let attributes = deepCopyFunction(props.main.attributes);
    let variables = deepCopyFunction(props.main.variables);
    let locationObjs = [];
    //insert an empty array for each file
    for (let f = 0; f < props.main.files.length; f++) {
      locationObjs.push([]);
    }
    let baseVariableIndex = 0;
    for (let i = 0; i < variables.length; i++) {
      const variable = variables[i];
      variable.originalIndex = i;
      variable.value = variable.variableName;
      variable.variableLocations = variable.variableLocations.sort(
        (a, b) => a.startIndex - b.startIndex
      );
      variable.variableLocations.forEach((variableLoc) => {
        if (variable.parentAttributeIndex !== null) {
          variableLoc.baseVariableIndex = null;
        } else {
          variableLoc.baseVariableIndex = baseVariableIndex;
        }
        variableLoc.type = "variableLocation";
        variableLoc.variableName = variable.variableName;
        variableLoc.parentAttributeIndex = variable.parentAttributeIndex;
        if (variable.parentAttributeIndex === null) {
          //look through attributes to see if baseVariable location belongs within an attributeLoc
          //if so we don't want to add the loc to the locationObjs we are mapping
          let inAnAttributeLoc = false;
          attributes.forEach((a) => {
            a.attributeLocations.forEach((aLoc) => {
              if(variableLoc.docIndex === aLoc.docIndex
                && variableLoc.startIndex >= aLoc.startIndex 
                && variableLoc.startIndex <= aLoc.endIndex
                && !a.variables.some((v) => v.originalIndex === variable.originalIndex)){
                a.variables.push(variable);
                inAnAttributeLoc = true;
              }
            })
          })
          if(!inAnAttributeLoc){
            //if not within an attributeLoc, then add it to the locationObjs array for mapping
            locationObjs[variableLoc.docIndex].push(variableLoc);
          } 
        }   
      });
      if (variable.parentAttributeIndex !== null) {
        attributes[variable.parentAttributeIndex].variables.push(variable);
      } else {
        baseVariableIndex++;
      }
    }
    variables = variables.filter((v) => v.parentAttributeIndex === null);

    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      attribute.originalIndex = i;
      attribute.attributeLocations = attribute.attributeLocations.sort(
        (a, b) => a.startIndex - b.startIndex
      );
      attribute.attributeLocations.forEach((attributeLoc) => {
        attributeLoc.attributeIndex = i;
        attributeLoc.type = "attributeLocation";
        attributeLoc.attributeName = attribute.attributeName;
        locationObjs[attributeLoc.docIndex].push(attributeLoc);
      });
    }
    locationObjs.forEach((locArr) =>
      locArr.sort((a, b) => a.startIndex - b.startIndex)
    );

    while (attributes.length > 0) {
      const deepestIndex = getGreatestDepthIndex(attributes);
      const parentOriginalIndex = attributes[deepestIndex].parentAttributeIndex;

      if (parentOriginalIndex === null) {
        newAttributes.push(attributes[deepestIndex]);
        attributes = props.removeAtIndex(attributes, deepestIndex);
      } else {
        let parent = attributes.find(
          (attr) => attr.originalIndex === parentOriginalIndex
        );
        parent.attributes.push(attributes[deepestIndex]);
        attributes = props.removeAtIndex(attributes, deepestIndex);
      }
    }
    setBaseVariables(variables);
    setPossibleAttributes(newAttributes);
    createDisplayObjs(locationObjs);
  };

  useEffect(() => {
    if(useEffectFired === false){
      createTree();
      setUseEffectFired(true)
    }
    
  }, []);

  const mapAttributeOption = (attribute, i) => {
    return <option key={`attributeOption-${i}`} value={attribute.originalIndex.toString()}>{attribute.attributeName}</option> 
  }
    

  const deepCopyFunction = (inObject) => {
    let outObject, value, key
  
    if (typeof inObject !== "object" || inObject === null) {
      return inObject // Return the value if inObject is not an object
    }
  
    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}
  
    for (key in inObject) {
      value = inObject[key]
  
      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = deepCopyFunction(value)
    }
  
    return outObject
  }

const addActiveAttribute = () => {
    if(attributeSelectId !== ''){
        const attrToCopy = possibleAttributes.find((a) => a.originalIndex === attributeSelectId);
        let attr = deepCopyFunction(attrToCopy);
        if(attr){
            let newActiveAttributes = [...activeAttributes];

            for (let i = 0; i < attr.variables.length; i++) {
              let v = attr.variables[i];
              v.activeAttributeIndex = newActiveAttributes.length;
              v.indexWithinParentVariables = i;
              if(v.variableLocations.length > 0){ 
                v.variableLocations.forEach((loc) => {
                  loc.activeAttributeIndex = newActiveAttributes.length;
                  loc.indexWithinParentVariables = i;
                })
              }   
            }
            newActiveAttributes.push(attr);
            setActiveAttributes(newActiveAttributes);
        } 
    }   
}

const handleVariableChange = (value, index, parentIndex, variable) => {
    if(parentIndex === null){
        let newBaseVariables = [...baseVariables];
        newBaseVariables[index].value = value;
        setBaseVariables(newBaseVariables);
    } else {
        // whenever I incorporate attributes within attributes, will need to revamp; parentIndex would not be sufficient
         let newActiveAttributes = [...activeAttributes];
         newActiveAttributes[variable.activeAttributeIndex].variables[variable.indexWithinParentVariables].value = value;
        //  newActiveAttributes[parentIndex].variables[index].value = value;
         setActiveAttributes(newActiveAttributes);
    }   
}


const mapVariableEditor = (variable, index, parentIndex) => {
        return(
            <div key={`vEditor-${index}`}>
                <div>
                 {`Variable Name ${index + 1}: ${variable.variableName}`}
                </div>
                <input
                    type='text'
                    value={variable.value}
                    onChange={(e) => handleVariableChange(e.target.value, index, parentIndex, variable)}
                />            
            </div>
            )  
}

const deleteAttribute = (index) => {
    let newActiveAttributes = [...activeAttributes];
    newActiveAttributes = props.removeAtIndex(newActiveAttributes, index);
    setActiveAttributes(newActiveAttributes);
}

const mapActiveAttributeEditor = (a, index) => {
  const variables = a.variables.filter((v) => v.parentAttributeIndex === a.originalIndex);
    return(
        <div key={`aEditor-${index}`}>
            <div>
             <span>{`Attribute Name ${index + 1}: ${a.attributeName}`}</span>
             <button style={{ backgroundColor: 'maroon', color: 'white'}} onClick={()=> deleteAttribute(index)}>Delete Attribute</button>
            </div>
            <div>
                {variables.length > 0
                ? <div style={{ marginLeft: 10 }}>
                    {variables.map((a, i) => mapVariableEditor(a, i, index))}
                </div>
                : null}
            </div> 

        </div>
        )  
}

const copyToClipboard = (text, documentName) => {
  const inputTemp = document.createElement('input');
  inputTemp.value = text;
  document.body.appendChild(inputTemp);
  inputTemp.select();
  document.execCommand('copy');
  document.body.removeChild(inputTemp);
  props.showSweetAlert(`${documentName} code copied to clipboard!`, '', 'success')
};

  return (
    <div id={props.id} key={props.id} style={{ display: "flex", flexDirection: "row-reverse" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          paddingLeft: 25,
        }}
      >
        <div>
          <h5>Variables</h5>
          {baseVariables.length > 0
          ? baseVariables.map((v, i) => mapVariableEditor(v, i, null))
         : null}
        </div>
        <div style={{ marginTop: 20 }}>
          <h5>Attributes</h5>
          {activeAttributes.length > 0
          ? activeAttributes.map((a, i) => mapActiveAttributeEditor(a, i))
          : null}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <select
            value={attributeSelectId.toString()}
            onChange={(e) => setAttributeSelectId(parseInt(e.target.value, 10))}
          >   <option value=''>Select Attribute</option>
              {possibleAttributes.map((a, i) => mapAttributeOption(a, i))}
          </select>
          <button
            style={{
              backgroundColor: "#8e41c4",
              color: "white",
              marginLeft: 5,
            }}
            className={styles.btn}
            onClick={addActiveAttribute}
          >
            Add Attribute
          </button>
        </div>
      </div>
      <div>{displayObjs.length > 0 && displayObjs[0].length > 0
            ? displayObjs.map((arr, i) => mapFinalCode(arr, i))
            : null}</div>
    </div>
  );
};

export { Templater };
