/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-assign */
/* eslint-disable no-loop-func */
import styles from "../styles/TemplateMaker.module.css";
import React, { useState, useEffect } from "react";
// import ReactTooltip from 'react-tooltip';
import { Templater } from "../components/Templater";
import Swal from "sweetalert2";
import { MapVariableEditor } from "../components/MapVariableEditor";
import { MapAttributeEditor } from "../components/MapAttributeEditor";


const initialMain = {
  files: [
    {
      fileName: "",
      originalCode: "",
    },
  ],
  variables: [],
  attributes: [],
};

const rawFile = {
  fileName: "",
  originalCode: "",
};

// const rawAttribute = {
//   parentAttributeIndex: null,
//   attributeName: "",
//   attributeLocations: [],
//   depth: 0,
// };

// const rawAttributeLocation = {
//   docIndex: null,
//   startIndex: null,
//   endIndex: null,
//   codeBlock: "",
// };

// const rawVariable = {
//   parentAttributeIndex: null,
//   variableName: "",
//   variableLocations: [],
//   depth: 0,
// };

// const rawVariableLocation = {
//   docIndex: null,
//   startIndex: null,
//   endIndex: null,
// };

export default function TemplateMaker() {
  const [isEditing, setIsEditing] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [usingRowSelection, setUsingRowSelection] = useState(false);
  const [main, setMain] = useState(initialMain);
  const [activeElement, setActiveElement] = useState(null);
  const [selection, setSelection] = useState([null, null, null]);
  const [charArr, setCharArr] = useState([]);
  const [templaterKey, setTemplaterKey] = useState('234234234');
  const [initialPageLoaded, setInitialPageLoaded] = useState(false);

   useEffect(() => {
    if(!initialPageLoaded){
      setInitialPageLoaded(true);
      Swal.fire('Reminder', 'When the page reloads all information is lost. We do not save your code or work. To prevent loss, save a copy of your template on step 2 of the process and you will be able to upload it again on step 1.', 'info')
    }
  },[])

  const addCodeInput = () => {
    let newMain = { ...main };
    newMain.files.push({ ...rawFile });
    setMain(newMain);
  };

  const removeCodeInput = () => {
    if (main.files.length > 1) {
      let newMain = { ...main };
      const lastIndex = newMain.files.length - 1;
      newMain.files = newMain.files.slice(0, lastIndex);
      if (newMain.attributes.length > 0) {
        newMain.attributes.forEach((a) => {
          a.attributeLocations = a.attributeLocations.filter(
            (loc) => loc.docIndex !== lastIndex
          );
        });
      }

      if (newMain.variables.length > 0) {
        newMain.variables.forEach((v) => {
          v.variableLocations = v.variableLocations.filter(
            (loc) => loc.docIndex !== lastIndex
          );
        });
      }

      setMain(newMain);
    }
  };

  const handleOriginalCode = (value, index) => {
    let newMain = { ...main };
    newMain.files[index].originalCode = value;
    setMain(newMain);
  };
  const handleFileName = (value, index) => {
    let newMain = { ...main };
    newMain.files[index].fileName = value;
    setMain(newMain);
  };
  const handleVariableName = (value, index) => {
    let newMain = { ...main };
    newMain.variables[index].variableName = value;
    setMain(newMain);
  };
  const handleAttributeName = (value, index) => {
    let newMain = { ...main };
    newMain.attributes[index].attributeName = value;
    setMain(newMain);
  };

  const mapTextArea = (file, index) => (
    <div key={`textArea-${index}`}>
      <label>{`File Name ${index + 1}`}</label>
      <input
        style={{ marginLeft: 10 }}
        type="text"
        value={file.fileName}
        onChange={(e) => handleFileName(e.target.value, index)}
      />
      <div>
        <label>{`Code Source ${index + 1}`}</label>
        <textarea
          className={styles.codeinput}
          rows={15}
          value={file.originalCode}
          onChange={(e) => handleOriginalCode(e.target.value, index)}
        />
      </div>
    </div>
  );

  const addAttribute = (parentAttributeIndex) => {
    let newMain = { ...main };
    let newAttribute = {
      parentAttributeIndex,
      attributeName: "",
      attributeLocations: [],
      variables: [],
      attributes: [],
      depth: parentAttributeIndex ? parentAttributeIndex + 1 : 0,
    };
    newMain.attributes.push(newAttribute);
    setMain(newMain);
    setActiveElement({
      type: "attribute",
      index: newMain.attributes.length - 1,
    });
  };

  const addAttributeLocation = (attributeIndex, attributeLocation) => {
    let nMain = { ...main };
    nMain.attributes[attributeIndex].attributeLocations.push(attributeLocation);
    setMain(nMain);
    setSelection([null, null, null]);
    resetCharArr(nMain); //verify
  };

  const addVariable = (parentAttributeIndex) => {
    let newMain = { ...main };
    let newVariable = {
      parentAttributeIndex,
      variableName: "",
      variableLocations: [],
      depth: parentAttributeIndex ? parentAttributeIndex + 1 : 0,
    };
    newMain.variables.push(newVariable);
    setMain(newMain);
    setActiveElement({ type: "variable", index: newMain.variables.length - 1 });
  };

  const addVariableLocation = (variableIndex, variableLocation) => {
    let nMain = { ...main };
    nMain.variables[variableIndex].variableLocations.push(variableLocation);
    setMain(nMain);
    setSelection([null, null, null]);
    resetCharArr(nMain);
  };

  const isUpperCase = (char) => {
    return char === char.toUpperCase();
  };

  const saveLocation = () => {
    let loc = {
      startIndex: selection[0],
      endIndex: selection[1],
      docIndex: selection[2],
    };
    if (activeElement.type === "variable") {
      loc.isUpperCase = isUpperCase(
        main.files[selection[2]].originalCode[selection[0]]
      );
      addVariableLocation(activeElement.index, loc);
    } else {
      let begin = selection[0];
      let end = selection[1];
      if (selection[1] < selection[0]) {
        end = selection[0];
        begin = selection[1];
      }
      loc.codeBlock = main.files[selection[2]].originalCode.slice(
        begin,
        end + 1
      );
      addAttributeLocation(activeElement.index, loc);
    }
  };

  const depthOfParentAttribute = (obj) => {
    let depth = 0;
    let curr = obj;
    while (curr.parentAttributeIndex) {
      depth += 1;
      curr = main.attributes[curr.parentAttributeIndex];//note change: curr.parentAttributeIndex
    }
    return depth;
  };

  const removeAtIndex = (array, index) => {
    if (index === 0) return array.slice(1);
    return array.slice(0, index).concat(array.slice(index + 1, array.length));
  };

  const handleRemoveLocation = (obj, index, loc, locationIndex, type) => {
    let newCharArr = [...charArr];
    let newMain = { ...main };
    if (type === "variable") {
      newMain.variables[index].variableLocations = removeAtIndex(
        newMain.variables[index].variableLocations,
        locationIndex
      );
    } else {
      newMain.attributes[index].attributeLocations = removeAtIndex(
        newMain.attributes[index].attributeLocations,
        locationIndex
      );
    }

    const depth = depthOfParentAttribute(obj);
    const style = getAttrStyle(depth);
    newCharArr[loc.docIndex]
      .slice(loc.startIndex, loc.endIndex + 1)
      .forEach((obj) => {
        obj.style = obj.prevStyle;
        obj.prevStyle = style; //not sure if this should just be an empty string;
        obj.assigmentType = null;
        obj.assignmentIndex = null;
      });
    setCharArr(newCharArr);
    setMain(newMain);
  };

  const mapOneVariableLocation = (
    variableLocation,
    locationindex,
    variableIndex,
    variable
  ) => {
    return (
      <div key={`vloc-${variableIndex}-${locationindex}`}>
        <button
          className={styles.btn}
          onClick={() =>
            handleRemoveLocation(
              variable,
              variableIndex,
              variableLocation,
              locationindex,
              "variable"
            )
          }
        >
          Remove
        </button>
        <span>{`doc: ${variableLocation.docIndex}, start: ${variableLocation.startIndex}, end: ${variableLocation.endIndex}`}</span>
      </div>
    );
  };

  const mapOneAttributeLocation = (
    attributeLocation,
    locationindex,
    attributeIndex,
    attribute
  ) => {
    return (
      <div key={`aloc-${attributeIndex}-${locationindex}`}>
        <button
          className={styles.btn}
          onClick={() =>
            handleRemoveLocation(
              attribute,
              attributeIndex,
              attributeLocation,
              locationindex,
              "attribute"
            )
          }
        >
          Remove
        </button>
        <span>{`doc: ${attributeLocation.docIndex}, start: ${attributeLocation.startIndex}, end: ${attributeLocation.endIndex}`}</span>
      </div>
    );
  };

  const getAllLocations = (text) => {
    let locations = [];
    if (text === "") return locations; //prevent infinite loop
    if (main.variables[activeElement.index].parentAttributeIndex !== null) {
      return getAllVariableSubLocations(text);
    }
    for (let docIndex = 0; docIndex < main.files.length; docIndex++) {
      const { originalCode } = main.files[docIndex];
      const length = text.length;
      let re = new RegExp(text.toLowerCase(), "gi");
      while (re.exec(originalCode.toLowerCase())) {
        const isValid = checkIfRowIsValid(
          re.lastIndex - length,
          re.lastIndex - 1,
          docIndex
        );
        if (isValid) {
          locations.push([re.lastIndex - length, re.lastIndex - 1, docIndex]);
        }
      }
    }
    return locations; 
  };

  const getAllLocationRows = (text) => {
    let locations = [];
    if (text === "") return locations; //prevent infinite loop
    for (let docIndex = 0; docIndex < main.files.length; docIndex++) {
      const { originalCode } = main.files[docIndex];
      const length = text.length;
      let re = new RegExp(text.toLowerCase(), "gi");
      while (re.exec(originalCode.toLowerCase())) {
        const [start, end] = getRowIndexes(re.lastIndex -length, docIndex);
        let isValid = checkIfRowIsValid(start, end, docIndex);
        
        if(isValid && locations.length > 0 && locations.some((loc)=> loc[0] === start && loc[1] === end && loc[2] === docIndex)){
              isValid = false;
        }
        if (isValid) {
          locations.push([start, end, docIndex]);
        }
      }
    }
    return locations;
  };

  const getAllVariableSubLocations = (text) => {
    let locations = [];
    if (text === "") return locations; //prevent infinite loop
    const { parentAttributeIndex } = main.variables[activeElement.index];
    const { attributeLocations } = main.attributes[parentAttributeIndex];
    attributeLocations.forEach((attrLoc) => {
      const { codeBlock } = attrLoc;
      const length = text.length;
      let re = new RegExp(text.toLowerCase(), "gi");
      while (re.exec(codeBlock.toLowerCase())) {
        const isValid = checkIfRowIsValid(
          re.lastIndex - length + attrLoc.startIndex,
          re.lastIndex - 1 + attrLoc.startIndex,
          attrLoc.docIndex
        );
        if (isValid) {
          locations.push([
            re.lastIndex - length + attrLoc.startIndex,
            re.lastIndex - 1 + attrLoc.startIndex,
            attrLoc.docIndex,
          ]);
        }
      }
    });
    return locations;
  };

  const saveAllVariableLocations = (text) => {
    let locations;
    if (main.variables[activeElement.index].parentAttributeIndex !== null) {
      locations = getAllVariableSubLocations(text);
    } else {
      locations = getAllLocations(text);
    }

    if (locations.length > 0) {
      let nMain = { ...main };

      locations.forEach((select) => {
        let loc = {
          startIndex: select[0],
          endIndex: select[1],
          docIndex: select[2],
        };
        if (activeElement.type === "variable") {
          loc.isUpperCase = isUpperCase(
            main.files[select[2]].originalCode[select[0]]
          );
          nMain.variables[activeElement.index].variableLocations.push(loc);
        } else {
          let begin = select[0];
          let end = select[1];
          if (select[1] < select[0]) {
            end = select[0];
            begin = select[1];
          }
          loc.codeBlock = main.files[select[2]].originalCode.slice(
            begin,
            end + 1
          );
          nMain.attributes[activeElement.index].attributeLocations.push(loc);
        }
      });

      setMain(nMain);
      setSelection([null, null, null]);
      resetCharArr(nMain);
    }
    //for each location go through charArr and see if it is occupied by another variable with null parentAttributeIndex
    //if not then mark them as a variable and save the variableLoc to the variable
  };

  const getAllAttributeSubLocationRows = (text) => {
    let locations = [];
    if (text === "") return locations; //prevent infinite loop
    const { parentAttributeIndex } = main.attributes[activeElement.index];
    const { attributeLocations } = main.attributes[parentAttributeIndex];
    attributeLocations.forEach((attrLoc) => {
      const { codeBlock, docIndex } = attrLoc;// note change: added docIndex to this line
      const length = text.length;
      let re = new RegExp(text.toLowerCase(), "gi");
      while (re.exec(codeBlock.toLowerCase())) {
        const [start, end] = getRowIndexes(re.lastIndex - length + attrLoc.startIndex, docIndex);
        const isValid = checkIfRowIsValid(start, end, docIndex);
        if (isValid) {
          locations.push([start, end, docIndex]);
        }
      }
    });
    return locations;
  };

  const saveAllAttributeLocations = (text) => {
    let locations;
    if (main.attributes[activeElement.index].parentAttributeIndex !== null) {
      locations = getAllAttributeSubLocationRows(text);
    } else {
      locations = getAllLocationRows(text);
    }

    if (locations.length > 0) {
      let nMain = { ...main };

      locations.forEach((select) => {
        let loc = {
          startIndex: select[0],
          endIndex: select[1],
          docIndex: select[2],
        };
        if (activeElement.type === "variable") {
          loc.isUpperCase = isUpperCase(
            main.files[select[2]].originalCode[select[0]]
          );
          nMain.attributes[activeElement.index].variableLocations.push(loc);
        } else {
          let begin = select[0];
          let end = select[1];
          if (select[1] < select[0]) {
            end = select[0];
            begin = select[1];
          }
          loc.codeBlock = main.files[select[2]].originalCode.slice(
            begin,
            end + 1
          );
          nMain.attributes[activeElement.index].attributeLocations.push(loc);
        }
      });

      setMain(nMain);
      setSelection([null, null, null]);
      resetCharArr(nMain);
    }
    //for each location go through charArr and see if it is occupied by another variable with null parentAttributeIndex
    //if not then mark them as a variable and save the variableLoc to the variable
  };

  const showSweetAlert = (title, description, type) => {
    Swal.fire(title, description, type);
  };

  const isActiveElementAChild = (index) => {
    let result = false;
    let curr = null;
    if (activeElement.type === "variable") {
      if ((curr = main.variables[activeElement.index])) {
        curr = main.variables[activeElement.index].parentAttributeIndex;
      }
    } else {
      if ((curr = main.attributes[activeElement.index])) {
        curr = main.attributes[activeElement.index].parentAttributeIndex;
      }
    }
    while (curr !== null) {
      if (curr === index) {
        return true;
      }

      if (main.attributes[curr]) {
        curr = main.attributes[curr].parentAttributeIndex;
      } else {
        curr = null;
      }
    }
    return result;
  };

  const mapCharObj = (c, i, docIndex) => {
    return {
      char: c,
      index: i,
      style: "",
      prevStyle: "",
      docIndex,
      assigmentType: null,
      assignmentIndex: null,
    };
  };

  const getAttrStyle = (depth) => {
    if (depth === 0) return "";
    if (depth === 1) return "attr1";
    if (depth === 2) return "attr2";
    if (depth === 3) return "attr3";
    if (depth === 4) return "attr4";
    return "attr4";
  };

  const resetCharArr = (newMain = null) => {
    newMain === null ? (newMain = main) : (newMain = newMain);
    let newCharArr = [];
    //go through each file
    for (let docIndex = 0; docIndex < newMain.files.length; docIndex++) {
      const file = newMain.files[docIndex];
      let docCharArr = Array.from(file.originalCode).map((c, i) =>
        mapCharObj(c, i, docIndex)
      );
      let depth = 0;
      let parents = [];
      let attrStyle = "attr0";
      //assign style to objs according to depth order
      for (let j = 0; j < newMain.attributes.length; j++) {
        const attr = newMain.attributes[j];
        //keep track of depth & corresponding style
        if (
          attr.parentAttributeIndex !== null &&
          !parents.includes(attr.parentAttributeIndex)
        ) {
          parents.push(attr.parentAttributeIndex);
          depth += 1;
          attrStyle = getAttrStyle(depth);
        }
        //paint all chars in attr locations
        if (attr.attributeLocations.length > 0) {
          attr.attributeLocations.forEach((loc) => {
            if (loc.docIndex === docIndex){
              docCharArr
              .slice(loc.startIndex, loc.endIndex + 1)
              .forEach((obj) => {
                obj.prevStyle = obj.style;
                obj.style = attrStyle;
                obj.assigmentType = "attribute";
                obj.assignmentIndex = j;
              });
            }
          });
        }
      }
      //assign style to objs for variables
      for (let k = 0; k < newMain.variables.length; k++) {
        const variable = newMain.variables[k];

        //paint all chars in variable locations
        if (variable.variableLocations.length > 0) {
          variable.variableLocations.forEach((loc) => {
            if (loc.docIndex === docIndex) {
              docCharArr
                .slice(loc.startIndex, loc.endIndex + 1)
                .forEach((obj) => {
                  obj.prevStyle = obj.style;
                  obj.style = "var0";
                  obj.assigmentType = "variable";
                  obj.assignmentIndex = k;
                });
            }
          });
        }
      }
      newCharArr.push(docCharArr);
    }
    setCharArr(newCharArr);
  };

  const deleteVariable = (index) => {
    if (main.variables[index].variableLocations.length === 0) {
      let newMain = { ...main };
      newMain.variables = removeAtIndex(newMain.variables, index);
      setMain(newMain);
    } else {
      Swal.fire(
        "Delete Locations First",
        `You must delete all this variable's locations first to before you can delete the variable itself.`,
        "info"
      );
    }
  };

  const deleteAttribute = (index) => {
    if (main.attributes[index].attributeLocations.length === 0) {
      let newMain = { ...main };
      newMain.attributes = removeAtIndex(newMain.attributes, index);
      setMain(newMain);
    } else {
      Swal.fire(
        "Delete Locations First",
        `You must delete all this attribute's locations first to before you can delete the attribute itself.`,
        "info"
      );
    }
  };

  const removeSelectionStyling = (obj) => {
    if (obj.style === "selection") {
      obj.style = obj.prevStyle;
      obj.prevStyle = "";
    }
  };

  const addSelectionStyling = (obj) => {
    if (obj.style !== "selection") {
      obj.prevStyle = obj.style;
    }
    obj.style = "selection";
  };

  const getRowIndexes = (initialIndex, docIndex) => {
    let result = [];

    let index = initialIndex;
    let curr = main.files[docIndex].originalCode[index];
    //get start
    while (curr !== "\n" && index !== 0) {
      curr = main.files[docIndex].originalCode[index - 1];
      index--;
    }
    result.push(index === 0 ? index : index + 1);
    //reset
    index = initialIndex;
    curr = main.files[docIndex].originalCode[index];
    //if we are already on a break, do next line
    if (curr === "\n" && index < main.files[docIndex].originalCode.length - 1) {
      index++;
      curr = main.files[docIndex].originalCode[index];
    }
    while (
      curr !== "\n" &&
      index !== main.files[docIndex].originalCode.length - 1
    ) {
      curr = main.files[docIndex].originalCode[index + 1];
      index++;
    }
    result.push(index);
    return result;
  };

  const checkIfRowIsValid = (start, end, docIndex) => {
    let isValid = true;
    for (let i = start; i < end; i++) {
      const charObj = charArr[docIndex][i];
      let activeElementParentIndex;
      if (activeElement && activeElement.type === "attribute") {
        activeElementParentIndex =
          main.attributes[activeElement.index].parentAttributeIndex;
      } else if (activeElement && activeElement.type === "variable") {
        activeElementParentIndex =
          main.variables[activeElement.index].parentAttributeIndex;
      }

      /*
                Invalid selection if...
                1) selection is for a variable and characters are already assigned to a variable
                2) selection is for an attribute and the characters are assigned to any attribute that does not have the same parent 
            */
      if (
        (charObj.assigmentType === "variable" &&
          activeElement &&
          activeElement.type === "variable") ||
        (activeElement &&
          activeElement.type === "attribute" &&
          activeElementParentIndex &&
          charObj.assignmentIndex !== activeElementParentIndex)
        // || activeElement && activeElement.type === 'attribute' && activeElementParentIndex === null && charObj.assigmentType === "variable" && charObj.assignmentIndex !== null
      ) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const selectCurrentSearchText = (select) => {
    handleClear();
    let isValid = checkIfRowIsValid(select[0], select[1], select[2]);
    if (isValid) {
      setSelection(select);
      let newCharArr = [...charArr];
      for (let i = select[0]; i < select[1] + 1; i++) {
        addSelectionStyling(newCharArr[select[2]][i]);
      }
      setCharArr(newCharArr);
    }
  };

  const selectTextRow = (charObj, docIndex) => {
    let start, end, isValid;
    if (usingRowSelection === false) {
      handleClear();
      [start, end] = getRowIndexes(charObj.index, docIndex);
      isValid = checkIfRowIsValid(start, end, docIndex);
      if (isValid) {
        let select = [start, end, docIndex];
        setSelection(select);
        let newCharArr = [...charArr];
        for (let i = start; i < end; i++) {
          addSelectionStyling(newCharArr[docIndex][i]);
        }
        setUsingRowSelection(true);
      }
    } else {
      [start, end] = getRowIndexes(charObj.index, docIndex);
      isValid = checkIfRowIsValid(start, end, docIndex);
      if (selection[2] !== docIndex) {
        isValid = false;
      }
      if (isValid) {
        if (selection[0] < start) {
          start = selection[0];
        }
        if (selection[1] > end) {
          end = selection[1];
        }
        let select = [start, end, docIndex];
        setSelection(select);
        let newCharArr = [...charArr];
        for (let i = start; i < end; i++) {
          addSelectionStyling(newCharArr[docIndex][i]);
        }
      }
    }
  };

  const sendCharIndex = (e, charObj, docIndex) => {
    if (e.ctrlKey) {
      selectTextRow(charObj, docIndex);
    } else {
      //if styled for variable, another parent, or something at the same 0 depth, do nothing.
      let activeElementParentIndex;
      if (activeElement && activeElement.type === "attribute") {
        activeElementParentIndex =
          main.attributes[activeElement.index].parentAttributeIndex;
      } else if (activeElement && activeElement.type === "variable") {
        activeElementParentIndex =
          main.variables[activeElement.index].parentAttributeIndex;
      }
      if (
        charObj.assigmentType === "variable" ||
        (activeElement &&
          activeElement.type === "attribute" &&
          activeElementParentIndex &&
          charObj.assignmentIndex !== activeElementParentIndex) ||
        (activeElement &&
          activeElement.type === "attribute" &&
          activeElementParentIndex === null &&
          charObj.assignmentIndex !== null)
      ) {
        return;
      }
      const charIndex = charObj.index;
      let select = [...selection];
      if (select[2] !== null && select[2] !== docIndex) {
        handleClear();
        return;
      } else {
        select[2] = docIndex;
      }

      //set selection and add styling
      let newCharArr = [...charArr];
      if (select[0] === null || charIndex < select[0]) {
        select[0] = charIndex;
        select[2] = docIndex;
        addSelectionStyling(newCharArr[docIndex][charIndex]);
        if (select[1] !== null) {
          select[1] = null;
        }
        newCharArr[docIndex]
          .slice(charIndex + 1)
          .forEach((obj) => removeSelectionStyling(obj));
        setSelection(select);
      } else {
        let begin, end;
        select[1] = charIndex;
        select[2] = docIndex;
        addSelectionStyling(newCharArr[docIndex][charIndex]);
        setSelection(select);

        //set begin/end of selection
        if (select[1] > select[0]) {
          begin = select[0];
          end = select[1];
        } else {
          begin = select[1];
          end = select[0];
        }

        //highlight in between
        if (end - begin > 1) {
          newCharArr[docIndex]
            .slice(begin + 1, end)
            .forEach((obj) => addSelectionStyling(obj));
        }

        //remove selection highlight before
        if (begin > 0) {
          newCharArr[docIndex]
            .slice(0, begin)
            .forEach((obj) => removeSelectionStyling(obj));
        }
        //remove selection highlight after
        if (end < newCharArr[docIndex].length - 1) {
          newCharArr[docIndex]
            .slice(end + 1)
            .forEach((obj) => removeSelectionStyling(obj));
        }
      }
      setCharArr(newCharArr);
    }
  };

  const handleClear = () => {
    setUsingRowSelection(false);
    if (selection[0] === null) return;

    let select = [...selection];
    let newCharArr = [...charArr];
    if (select[0] !== null && select[1] === null) {
      removeSelectionStyling(newCharArr[select[2]][select[0]]);
    }

    let begin, end;
    if (select[1] > select[0]) {
      begin = select[0];
      end = select[1];
    } else {
      begin = select[1];
      end = select[0];
    }

    newCharArr[select[2]]
      .slice(begin, end + 1)
      .forEach((obj) => removeSelectionStyling(obj));
    setCharArr(newCharArr);
    setSelection([null, null, null]);
  };

  const mapCharDivsFromArr = (arr, docIndex) => {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      const charObj = arr[i];
      result.push(
        <div
          key={`char${charObj.index}`}
          id={`d-${docIndex}-i-${charObj.index}`}
          className={styles[charObj.style]}
          onClick={(e) => sendCharIndex(e, charObj, docIndex)}
          // data-tip={hint}
          // data-for='hint'
        >
          {charObj.char}
        </div>
      );
      if (charObj.char === "\n") {
        result.push(<div key={`linebreak-${charObj.index}`} style={{ flexBasis: "100%" }}></div>);
      }
    }
    return result;
  };

  //separate charArr mapper to separate function in order to include the \n div
  const mapOneEditor = (file, index) => (
    <div style={{ maxWidth: 1000 }} key={`cDivEditor-${index}`}>
      <label>{file.fileName}</label>
      <div className={styles.codecontainer}>
        {mapCharDivsFromArr(charArr[index], index)}
        {/* {charArr[index].map((charObj) => charDiv(charObj, index))} */}
      </div>
    </div>
  );

  const handleNext = () => {
    if (!isEditing) {
      resetCharArr();
      setIsEditing(true);
    } else {
      const key = Math.floor(Math.random() * 1000000).toString();
      setTemplaterKey(key);
      setShowTemplate(true);
    }
  };

  const handlePrev = () => {
    if (showTemplate) {
      setShowTemplate(false);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "This will delete all variables and attributes",
        showCancelButton: true,
        confirmButtonText: "Ok, lets start over",
      }).then((result) => {
        if (result.isConfirmed) {
          let newMain = { ...main };
          newMain.variables = [];
          newMain.attributes = [];
          setMain(newMain);
          setActiveElement(null);
          setSelection([null, null, null]);
          setCharArr([]);
          setIsEditing(false);
        }
      });
    }
  };

  const mapVariableEditorsByParentAttributeIndex = (parentAttributeIndex) => {
    let result = null;

    if (main.variables.length > 0) {
      result = [];
      for (let i = 0; i < main.variables.length; i++) {
        const variable = main.variables[i];
        if (variable.parentAttributeIndex === parentAttributeIndex) {
          // result.push(mapVariableEditor(variable, i))
          result.push(
            <MapVariableEditor
              key={`varEdit-${parentAttributeIndex}-${i}`}
              variable={variable}
              index={i}
              activeElement={activeElement}
              setActiveElement={setActiveElement}
              selection={selection}
              saveLocation={saveLocation}
              deleteVariable={deleteVariable}
              mapOneVariableLocation={mapOneVariableLocation}
              handleVariableName={handleVariableName}
              getAllLocations={getAllLocations}
              saveAllVariableLocations={saveAllVariableLocations}
              showSweetAlert={showSweetAlert}
              selectCurrentSearchText={selectCurrentSearchText}
              removeAtIndex={removeAtIndex}
              
            />
          );
        }
      }
    }

    return result;
  };

  const mapAttributeEditorsByParentAttributeIndex = (parentAttributeIndex) => {
    let result = null;

    if (main.attributes.length > 0) {
      result = [];
      for (let i = 0; i < main.attributes.length; i++) {
        const attribute = main.attributes[i];
        if (attribute.parentAttributeIndex === parentAttributeIndex) {
          result.push(
            <MapAttributeEditor
              key={`attEdit-${parentAttributeIndex}-${i}`}
              attribute={attribute}
              index={i}
              activeElement={activeElement}
              setActiveElement={setActiveElement}
              selection={selection}
              selectCurrentSearchText={selectCurrentSearchText}
              saveLocation={saveLocation}
              deleteAttribute={deleteAttribute}
              mapOneAttributeLocation={mapOneAttributeLocation}
              handleAttributeName={handleAttributeName}
              getAllLocationRows={getAllLocationRows}
              saveAllAttributeLocations={saveAllAttributeLocations}
              showSweetAlert={showSweetAlert}
              removeAtIndex={removeAtIndex}
              addVariable={addVariable}
              isActiveElementAChild={isActiveElementAChild}
              mapAttributeEditorsByParentAttributeIndex={
                mapAttributeEditorsByParentAttributeIndex
              }
              mapVariableEditorsByParentAttributeIndex={
                mapVariableEditorsByParentAttributeIndex
              }
            />
          );
        }
      }
    }

    return result;
  };

  const handleUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      const newMain = JSON.parse(e.target.result);
      setMain(newMain);
      setIsEditing(true);
      setShowTemplate(false);
      resetCharArr(newMain);
    };
  };

  const handleDownloadFile = () => {
    Swal.fire({
      title: "Name your template file",
      input: "text",
      inputValue: "TemplateName",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Save",
      showLoaderOnConfirm: true,
      preConfirm: (fileName) => {
        saveTemplateAsFile(fileName, main);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const saveTemplateAsFile = (filename, dataObjToWrite) => {
    const blob = new Blob([JSON.stringify(dataObjToWrite)], {
      type: "text/json",
    });
    const link = document.createElement("a");

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/json", link.download, link.href].join(
      ":"
    );

    const evt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove();
  };

  // useEffect(() => {
  //     ReactTooltip.rebuild();
  // },[addAttributeLocation, addVariableLocation, handleRemoveLocation])

  return (
    <div className={styles.container}>
      
      <main className={styles.main}>
        <div style={{ width: "500px", marginBottom: 20 }} className={styles.rowspaced}>
          {!isEditing ? (
            <div>
              <div style={{ fontWeight: 700, color: 'rgb(109, 109, 109)'}}>Upload Template</div>
              <input type="file" onChange={handleUpload} />
            </div>
          ) : null}

          {isEditing && !showTemplate? (
            <button
              className={styles.btn}
              style={{ backgroundColor: "#cccccc" }}
              onClick={handleDownloadFile}
            >
              Save Template SetUp
            </button>
          ) : null}

          <button
              onClick={handlePrev}
              className={ !isEditing && !showTemplate ? styles.linkbtndisabled : styles.linkbtn}
              disabled={!isEditing && !showTemplate}
            >
              {`<< Prev`}
          </button>
          
          <button
              onClick={handleNext}
              className={showTemplate || main.files[0].originalCode.length === 0 ? styles.linkbtndisabled : styles.linkbtn}
              disabled={showTemplate || main.files[0].originalCode.length === 0}
            >
              {`Next  >>`}
          </button>
          
        </div>
        {!isEditing ? (
          <div>
            {main.files.map((file, index) => mapTextArea(file, index))}
            <div  className={styles.rowspaced}>
              <div>
                <button onClick={addCodeInput} className={styles.primarybtn}>
                  Add Code Source
                </button>
              </div>
              <div>
                <button
                  disabled={main.files.length === 1}
                  onClick={removeCodeInput}
                  className={styles.dangerbtn}
                >
                  Remove Last Source
                </button>
              </div>
            </div>
          </div>
        ) : !showTemplate ? (
          <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: 20 }}>
            <div
              className={styles.sidebar}
              style={{
                display: "flex",
                flexDirection: "column",
                height: "90vh",
                paddingLeft: 25,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  className={styles.btn}
                  style={{ backgroundColor: "#cccccc", marginRight: 5 }}
                  onClick={handleClear}
                >
                  Clear Selection
                </button>
                <button
                  style={{ backgroundColor: "#2f8f6a", color: "white" }}
                  className={styles.btn}
                  onClick={() => addVariable(null)}
                >
                  Add Variable
                </button>
                <button
                  style={{
                    backgroundColor: "#8e41c4",
                    color: "white",
                    marginLeft: 5,
                  }}
                  className={styles.btn}
                  onClick={() => addAttribute(null)}
                >
                  Add Attribute
                </button>
              </div>
              <div className={styles.sidebarInner}>
                <h5 style={{marginTop: 0 }}>Variables</h5>
                {mapVariableEditorsByParentAttributeIndex(null)}
              </div>
              <div className={styles.sidebarInner}>
                <h5 style={{marginTop: 0 }}>Attributes</h5>
                {mapAttributeEditorsByParentAttributeIndex(null)}
              </div>
            </div>
            <div>{main.files.map((file, i) => mapOneEditor(file, i))}</div>
          </div>
        ) : (
          <Templater
            key={templaterKey}
            id={templaterKey}
            main={main}
            showSweetAlert={showSweetAlert}
            removeAtIndex={removeAtIndex}
          />
        )}
        {/* <ReactTooltip id="hint" place="top"/> */}
      </main>
    </div>
  );
}
