import React, { useState } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp, AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import styles from "../styles/TemplateMaker.module.css";

const MapAttributeEditor = (props) => {
  const {
    attribute,
    index,
    activeElement,
    setActiveElement,
    selection,
    selectCurrentSearchText,
    saveLocation,
    deleteAttribute,
    mapOneAttributeLocation,
    handleAttributeName,
    getAllLocationRows,
    saveAllAttributeLocations,
    showSweetAlert,
    // removeAtIndex,
    addVariable,
    isActiveElementAChild,
    mapAttributeEditorsByParentAttributeIndex,
    mapVariableEditorsByParentAttributeIndex
  } = props;
  const [showFindAndSave, setShowFindAndSave] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const [findText, setFindText] = useState("");
  const [searchLocations, setSearchLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null); //index within searchLocations

  const handleFindText = (val) => {
    setFindText(val);
    const locs = getAllLocationRows(val);
    setSearchLocations(locs);
  };

  const handleShowFindAndSave = () => {
    if (showFindAndSave) {
      setFindText("");
      setSearchLocations([]);
    }
    setShowFindAndSave(!showFindAndSave);
  };

  const scrollToMiddle = (element) => {
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop - window.innerHeight / 2;
    window.scrollTo(0, middle);
  };

  const goToPrevLoc = () => {
    let newCurrentLocation;
    if (currentLocation === null) {
      newCurrentLocation = 0;
    } else if (currentLocation === 0) {
      newCurrentLocation = currentLocation;
      showSweetAlert(
        "At the first location",
        "Click the down arrow to see other locations",
        "info"
      );
    } else {
      newCurrentLocation = currentLocation - 1;
    }

    if (searchLocations.length > 0) {
      const curr = searchLocations[newCurrentLocation];
      selectCurrentSearchText(curr);
      let element = document.getElementById(`d-${curr[2]}-i-${curr[0]}`);
      scrollToMiddle(element);
    }

    setCurrentLocation(newCurrentLocation);
  };

  const goToNextLoc = () => {
    let newCurrentLocation;
    if (currentLocation === null) {
      newCurrentLocation = 0;
    } else if (currentLocation === searchLocations.length - 1) {
      newCurrentLocation = currentLocation;
      showSweetAlert(
        "At the last location",
        "Click the up arrow to see other locations",
        "info"
      );
    } else {
      newCurrentLocation = currentLocation + 1;
    }

    if (searchLocations.length > 0) {
      const curr = searchLocations[newCurrentLocation];
      selectCurrentSearchText(curr);
      let element = document.getElementById(`d-${curr[2]}-i-${curr[0]}`);
      scrollToMiddle(element);
    }

    setCurrentLocation(newCurrentLocation);
  };

  const handleSaveLocation = () => {
    if (currentLocation !== null) {
      saveLocation();
      if (searchLocations.length > 1) {
        setTimeout(() => {
          goToNextLoc();
        }, 5000);

        // const newSearchLocations = removeAtIndex(searchLocations, currentLocation);
        // setSearchLocations(newSearchLocations);
        // const curr = newSearchLocations[currentLocation];
        // selectCurrentSearchText(curr);
        // let element = document.getElementById(`d-${curr[2]}-i-${curr[0]}`);
        // scrollToMiddle(element);
      }
    }
  };

  if (
    (activeElement &&
      activeElement.type === "attribute" &&
      activeElement.index === index) ||
    (activeElement && isActiveElementAChild(index))
  ) {
    return (
      <div key={`attrEditor-${index}`}>
        <div>
          <button
            className={styles.btn}
            style={{ marginRight: 10 }}
            onClick={() => setActiveElement(null)}
          >
            Close
          </button>
          <span>{`Attribute Name ${index + 1}`} </span>
          <button
            style={{ backgroundColor: "maroon", color: "white" }}
            className={styles.btn}
            onClick={() => deleteAttribute(index)}
          >
            Delete
          </button>
        </div>
        <input
          type="text"
          placeholder="attribute name"
          value={attribute.attributeName}
          onChange={(e) => handleAttributeName(e.target.value, index)}
        />
        <div>
          {activeElement &&
          activeElement.type === "attribute" &&
          activeElement.index === index ? null : (
            <button
              onClick={() => setActiveElement({ type: "attribute", index })}
            >
              Edit
            </button>
          )}

          {activeElement &&
          activeElement.type === "attribute" &&
          activeElement.index === index &&
          selection[0] !== null &&
          selection[1] !== null &&
          selection[2] !== null ? (
            <button
              className={styles.btn}
              style={{ backgroundColor: "green", color: "white" }}
              onClick={saveLocation}
            >
              Save Location
            </button>
          ) : null}

          <div style={{ marginTop: 5 }}>
            <span>Locations</span>
            <button
              className={styles.btn}
              style={{ marginLeft: 5, backgroundColor: "gray", color: "white" }}
              onClick={handleShowFindAndSave}
            >
              {showFindAndSave ? "Close Search" : "Search"}
            </button>
          </div>

          {showFindAndSave ? (
            <div style={{ marginTop: 8 }}>
              <input
                type="text"
                placeholder="text to search"
                value={findText}
                onChange={(e) => handleFindText(e.target.value)}
                style={{ marginRight: 5 }}
              />
              <span>
                {searchLocations.length === 0
                  ? "No Locations"
                  : selection[0] !== null &&
                    selection[1] !== null &&
                    selection[2] !== null &&
                    currentLocation !== null
                  ? `${currentLocation + 1} of ${searchLocations.length}`
                  : `? of ${searchLocations.length}`}
              </span>
              <div>
                <span>
                  <button
                    style={{
                      backgroundColor: selection[0] === null ? null : "green",
                      color: selection[0] === null ? "#808080" : "white",
                      marginRight: 15,
                    }}
                    className={styles.btn}
                    onClick={handleSaveLocation}
                    disabled={selection[0] === null}
                  >
                    Save & Next
                  </button>
                  <button
                    style={{ backgroundColor: "green", color: "white" }}
                    className={styles.btn}
                    onClick={() => saveAllAttributeLocations(findText)}
                  >
                    Save All
                  </button>
                </span>
                <span style={{ marginLeft: 20 }}>
                  <button
                    style={{ backgroundColor: "gray", color: "white" }}
                    className={styles.btn}
                    onClick={goToPrevLoc}
                  >
                    <AiOutlineArrowUp />
                  </button>
                  <button
                    style={{
                      backgroundColor: "gray",
                      color: "white",
                      marginLeft: 3,
                    }}
                    className={styles.btn}
                    onClick={goToNextLoc}
                  >
                    <AiOutlineArrowDown />
                  </button>
                </span>
              </div>
            </div>
          ) : null}

          {showLocations && attribute.attributeLocations.length > 0
          ? <div>
              <button
                    style={{
                      marginLeft: 3,
                    }}
                    className={styles.btn}
                    onClick={() => setShowLocations(!showLocations)}
                  >
                    <AiOutlineMinusSquare />
                </button>
              {attribute.attributeLocations.map((loc, i) =>
                mapOneAttributeLocation(loc, i, index, attribute)
              )}
            </div>
          : <div>
              <button
                    style={{
                      marginLeft: 3,
                    }}
                    className={styles.btn}
                    onClick={() => setShowLocations(!showLocations)}
                  >
                    <AiOutlinePlusSquare />
                </button>
              <span>{`${attribute.attributeLocations.length} Locations`}</span>
            </div>}
            
        </div>
        <div>
          <button
            style={{ backgroundColor: "lightgreen" }}
            className={styles.btn}
            onClick={() => addVariable(index)}
          >
            Add Sub-Variable
          </button>
        </div>
        <div style={{ paddingLeft: 10 }}>
          {mapVariableEditorsByParentAttributeIndex(index)}
        </div>
        {/* <div>
                        <button 
                            style={{ backgroundColor: '#CF9FFF' }}
                            onClick={() => addAttribute(index)}
                            >
                            Add Sub-Attribute
                        </button>
                    </div> */}
        <div style={{ paddingLeft: 10 }}>
          {mapAttributeEditorsByParentAttributeIndex(index)}
        </div>
      </div>
    );
  }
  return (
    <div key={`attrEditor-${index}`}>
      <button
        className={styles.btn}
        style={{ backgroundColor: "#cccccc", marginRight: 5 }}
        onClick={() => setActiveElement({ type: "attribute", index })}
      >
        Edit
      </button>
      <label>{`Attribute Name ${index + 1}: ${attribute.attributeName}`}</label>
    </div>
  );
};

export { MapAttributeEditor };
