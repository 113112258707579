import React from 'react';
import Logo from "../assets/images/logo.png";
import styles from "../styles/Header.module.css";

const Header = () => {
    return (
    <div className={styles.header}>
        <img src={Logo} alt='logo' style={{ height: 40 }}/>
        <div className={styles.headerText}>CODE TEMPLATE MAKER</div>
      </div>
    )
}

export { Header }